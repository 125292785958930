import React, { FC, useEffect, useState } from 'react';
import styled from './Login.module.css';
import Button from '../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../constants/Routes';
import useStorage from '../../hooks/useStorage';
import UserService from '../../services/UserService';
import { AxiosError, AxiosResponse } from 'axios';
import Input from '../../components/Input/Input';
import { userNameRegex } from '../../constants/Validation';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import MfaService from '../../services/MfaService';
import InputOtp from '../../components/InputOtp/InputOtp';
import LoadingSpinnerSmall from '../../components/LoadingSpinner/LoadingSpinnerSmall';
import mfaShield from '../../assets/images/mfaShield.svg';

enum MfaMethod {
    EMAIL = 'EMAIL',
}

interface MfaRequiredResponse {
    error: 'MFA_REQUIRED';
    method: MfaMethod;
    token: string;
}

const Login: FC = () => {
    const { setToken } = useStorage();
    const history = useHistory();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string | undefined>(undefined);
    const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [forgotPasswordModalState, setForgotPasswordModalState] = useState<{
        open: boolean;
        close?: boolean;
    }>({ open: false });
    const [mfaToken, setMfaToken] = useState<string | undefined>();
    const [mfaMethod, setMfaMethod] = useState<string | undefined>();
    const [showMfaRetrySuccess, setShowMfaRetrySuccess] = useState<boolean>(false);

    //

    const handleUsernameChange = (value: string): void => {
        setUsername(value.replace(/\s/g, ''));
    };

    const handlePasswordChange = (value: string): void => {
        setPassword(value);
    };

    const validateUserName = () => {
        //Validate
        const valid: boolean = username.length > 0 && userNameRegex(username);

        if (!valid) {
            setUserNameError('E-postadressen är inte giltig');
        }

        return valid;
    };

    const validatePassword = () => {
        const valid: boolean = password.length > 0;
        console.log(username);

        if (!valid) {
            setPasswordError('Lösenord måste vara ifyllt');
        }

        return valid;
    };

    const createAuthHandler = () => {
        const startTimestamp = new Date().getTime();
        const forcedDelay = 1000;

        return (response: AxiosResponse) => {
            const responseTimestamp = new Date().getTime();
            const difference = startTimestamp - responseTimestamp + forcedDelay;

            if (difference > 0) {
                setTimeout(() => {
                    setToken(response.headers.authorization);
                    setLoading(false);
                    history.push(HOME);
                }, difference);
            } else {
                setToken(response.headers.authorization);
                setLoading(false);
                history.push(HOME);
            }
        };
    };

    const authenticate = () => {
        const authHandler = createAuthHandler();

        setLoading(true);
        UserService()
            .authenticateUser(username, password)
            .then(authHandler)
            .catch((error: AxiosError<MfaRequiredResponse | undefined>) => {
                const status = error.response?.status;
                const duration = error.response?.headers.duration;
                const email = error.response?.headers.email;
                const data = error.response?.data;

                if (status === 403 && duration !== undefined && email !== undefined) {
                    setError(`Too many attempts, ${username} will be locked for ${duration} min.`);
                } else if (status === 401) {
                    if (data && data.error === 'MFA_REQUIRED') {
                        setMfaToken(data.token);
                        setMfaMethod(data.method);

                        // Create an entry so that the use can always go back from the mfa form
                        history.push(window.location.pathname, { replace: false });
                    } else {
                        setError('Epostadress och lösenord matchar inte!');
                    }
                } else {
                    setError('Ett oväntat fel uppstod.');
                }

                setPassword('');
                setLoading(false);
            });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setError(undefined);
        setPasswordError(undefined);
        setUserNameError(undefined);

        const passwordValid: boolean = validatePassword();
        const userNameValid: boolean = validateUserName();

        if (passwordValid && userNameValid) {
            authenticate();
        }
    };

    const verifyMfa = (code: string): void => {
        const authHandler = createAuthHandler();

        setError('');
        setLoading(true);
        if (mfaToken) {
            MfaService()
                .verify({ token: mfaToken, code })
                .then(authHandler)
                .catch((error: AxiosError<string>) => {
                    const status = error.response?.status;
                    const data = error.response?.data;

                    if (data === 'MFA_INVALID_CODE') {
                        setError('Angiven kod ej korrekt.');
                    } else if (data === 'MFA_SESSION_EXPIRED') {
                        setError('Koden är inte längre giltig, det tog för lång tid.');
                    } else if (status === 401) {
                        setError('Koden har blivit ogiltig.');
                    } else {
                        setError('Ett oväntat fel uppstod.');
                    }

                    setMfaToken(undefined);
                    setLoading(false);
                });
        }
    };

    const reDispatchMfa = (): void => {
        setError('');
        setLoading(true);
        if (mfaToken) {
            MfaService()
                .reDispatch({ token: mfaToken })
                .then((res): void => {
                    setMfaToken(res.data);
                    setShowMfaRetrySuccess(true);
                    setTimeout(() => setShowMfaRetrySuccess(false), 4000);
                    setLoading(false);
                })
                .catch((error: AxiosError<string>) => {
                    setError('Misslyckades med att skicka om verifierings-koden. Försök igen.');

                    setMfaToken(undefined);
                    setMfaMethod(undefined);
                    setLoading(false);
                });
        }
    };

    const handleOpenForgotPasswordModal = (): void => {
        setForgotPasswordModalState({ open: true });
    };

    const handleCloseForgotPasswordModal = (): void => {
        setForgotPasswordModalState({ open: false });
    };

    const handleMfaReset = () => {
        setError('');
        setMfaMethod(undefined);
    };

    // Since we created a new history entry, using this effect, going back to the previous entry reverts to the login form.
    useEffect(() => {
        let isMounted = true;

        const cleanup = history.listen((location, action) => {
            if (action === 'POP' && isMounted) handleMfaReset();
        });

        return () => {
            isMounted = false; // Because calling hooks when unmounted is a no-op
            cleanup();
        };
    }, [history]);

    console.log({ showMfaRetrySuccess });

    return (
        <div className={styled.LoginBackground}>
            {mfaMethod === MfaMethod.EMAIL ? (
                <div className={styled.Mfa}>
                    <div className={styled.Header}>
                        <div>
                            <img src={mfaShield}></img>
                        </div>
                        <p>Verifiera konto</p>
                    </div>
                    <p className={styled.MfaLabel}>Fyll i den 6-siffriga koden skickad till din mejladdress:</p>
                    <InputOtp disabled={loading || !!error} onComplete={verifyMfa} />
                    {error ? (
                        <>
                            <div className={styled.ErrorDiv}>
                                <p className={styled.Error}>{error}</p>
                            </div>
                            <div className={styled.ButtonGroup}>
                                <Button variant="Light" onClick={handleMfaReset} className={styled.PwdBtn}>
                                    Försök igen
                                </Button>
                            </div>
                        </>
                    ) : loading ? (
                        <LoadingSpinnerSmall />
                    ) : (
                        <Button variant="Light" className={styled.PwdBtn} onClick={reDispatchMfa} disabled={showMfaRetrySuccess}>
                            {showMfaRetrySuccess ? 'Mejl skickat' : 'Skicka igen'}
                        </Button>
                    )}
                </div>
            ) : (
                <div className={styled.Login}>
                    <div className={styled.Header}>
                        <p>Välkommen till Lagbevakningen</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styled.InputGroup}>
                            <Input className={styled.Input} type="text" autoComplete="username" label="E-postadress" value={username} onChange={handleUsernameChange} autoFocus />
                            <Input className={styled.Input} type="password" autoComplete="current-password" label="Lösenord" value={password} onChange={handlePasswordChange} />
                            <div className={styled.ErrorDiv}>
                                {error && <p className={styled.Error}>{error}</p>}
                                {userNameError && <p className={styled.Error}>{userNameError}</p>}
                                {passwordError && <p className={styled.Error}>{passwordError}</p>}
                            </div>
                        </div>
                        <div className={styled.ButtonGroup}>
                            <Button variant="Primary" type="submit" disabled={loading} className={styled.LoginBtn}>
                                {!loading ? 'Logga in' : 'Laddar...'}
                            </Button>
                            <Button variant="Light" className={styled.PwdBtn} onClick={handleOpenForgotPasswordModal}>
                                {'Glömt lösenord'}
                            </Button>
                        </div>
                    </form>
                </div>
            )}
            {forgotPasswordModalState.open && <ForgotPassword onClose={handleCloseForgotPasswordModal} />}
        </div>
    );
};

export default Login;
