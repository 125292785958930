import React, { ClipboardEvent, FC, KeyboardEvent, FocusEvent, useEffect, useRef, useState } from 'react';

import Input from '../Input/Input';
import styled from './InputOtp.module.css';

interface InputOtpProps {
    length?: number;
    disabled?: boolean;
    onComplete?: (otp: string) => void;
}

const InputOtp: FC<InputOtpProps> = ({ length = 6, disabled, onComplete }) => {
    const [values, setValues] = useState(Array(length).fill(''));
    const inputsRef = useRef<HTMLInputElement[]>([]);

    const createHandleChange = (index: number) => (value: string): void => {
        const newValue = value.replace(/[^0-9]/g, ''); // Only numeric input

        if (!newValue) return;

        const newValues = [...values];
        newValues[index] = newValue;
        setValues(newValues);

        // Focus next input
        if (index < length - 1) inputsRef.current[index + 1]?.focus();

        // Notify parent if all inputs are filled
        if (newValues.every(val => val !== '')) onComplete?.(newValues.join(''));
    };

    const createHandleKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Backspace') {
            const newValues = [...values];

            if (values[index] === '') {
                // Move focus to the previous input
                if (index > 0) inputsRef.current[index - 1]?.focus();
            } else {
                // Clear current input
                newValues[index] = '';
                setValues(newValues);
            }
        } else if (e.key === 'ArrowLeft' && index > 0) {
            inputsRef.current[index - 1]?.focus();
        } else if (e.key === 'ArrowRight' && index < length - 1) {
            inputsRef.current[index + 1]?.focus();
        }
    };

    const formatPastedData = (data: string): string[] => {
        const digits = data
            .replace(/[^0-9]/g, '')
            .slice(0, length)
            .split('');

        return digits.concat(Array(length - digits.length).fill('')); // Add empty spaces if there are any;
    };

    // Handle pasting into inputs
    const createHandlePaste = (e: ClipboardEvent<HTMLInputElement>): void => {
        e.preventDefault();

        const newValues = formatPastedData(e.clipboardData.getData('text'));

        setValues(newValues);

        // Notify parent if all inputs are filled
        if (newValues.every(val => val !== '')) onComplete?.(newValues.join(''));

        // Focus the first empty input after pasting
        const firstEmptyIndex = newValues.findIndex(val => val === '');
        if (firstEmptyIndex !== -1) inputsRef.current[firstEmptyIndex]?.focus();
    };

    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
        e.target.select();
    };

    useEffect(() => {
        // Because can not get autoFocus prop on input to work
        setTimeout(() => {
            inputsRef.current[0].focus();
        }, 300);
    }, []);

    return (
        <div className={styled.Wrapper}>
            {values.map((value, index) => (
                <Input
                    key={index}
                    autoFocus={index === 0}
                    type="text"
                    inputMode="numeric"
                    maxLength={1}
                    disabled={disabled}
                    value={value}
                    onChange={createHandleChange(index)}
                    onKeyDown={createHandleKeyDown(index)}
                    onFocus={handleOnFocus}
                    onPaste={createHandlePaste}
                    ref={(el): void => {
                        if (el) inputsRef.current[index] = el;
                    }}
                    aria-label={`Digit ${index + 1}`}
                />
            ))}
        </div>
    );
};

export default InputOtp;
