import { AxiosError, AxiosResponse } from 'axios';

import { getApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import MfaServiceParams from '../models/MfaServiceParams';

const MfaService = () => {
    const axiosInstance = getApi();

    function verify(params: MfaServiceParams): Promise<AxiosResponse<void>> {
        return axiosInstance.post<void>('/mfa/verify', params).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    /**
     * Used to "resend" and mfa request. Note that it uses MfaServiceParams but only cares about the required token
     * field, not the other optionals.
     * @param params
     */

    function reDispatch(params: MfaServiceParams): Promise<AxiosResponse<string>> {
        return axiosInstance.post<string>('/mfa/re-dispatch', params).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    return { verify, reDispatch };
};

export default MfaService;
